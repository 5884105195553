<template>
  <div class="container">
    <UserNav/>
    <h1 class="page-title">МОЙ ПРОФИЛЬ</h1>
    <div class="cards">
<!--
      <div class="cards-item">
        <div class="cards-item__row">
          <span class="cards-item__row__status blue"></span>
          <span class="cards-item__row__title">Мой профиль</span>
        </div>
        <div class="cards-item__row">
          <span class="cards-item__row__name">Литвинов Артемий Васильевич</span>
          <span class="cards-item__row__number">+7 (909) 332 22 10</span>
          <svg-notice/>
        </div>
        <button class="cards-item__remove">Изменить данные</button>
      </div>
-->
      <router-link
        to="/shop"
        tag="button"
        class="btn middle btn--pay">
        <svg-home/>
        <span>НА ГЛАВНУЮ</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import UserNav from '@/components/UserNav'
import SvgHome from '@/assets/svg/home.svg'
import SvgNotice from '@/assets/svg/notice.svg'

export default {
  name: 'Profile',
  components: {
    UserNav,
    SvgHome,
    SvgNotice
  }
}
</script>

<style lang="scss">
.cards-item {
  background: #FEFEFE;
  border-radius: 18px;
  padding: 15px 70px;
  margin-bottom: 10px;

  @include sm {
    padding: 16px 16px 16px 50px;
  }
}

.cards-item__row {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @include sm {
    flex-wrap: wrap;
  }
}

.cards-item__row__status {
  position: absolute;
  left: -50px;
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;

  @include sm {
    left: -30px;
  }

  &.blue {
    background: #2F6BF0;
  }

  &.light-blue {
    background: #94E4F9;
  }
}

.cards-item__row__title,
.cards-item__row__name {
  font-weight: 600;
  font-size: 14px;
}

.cards-item__row__name {
  @include sm {
    text-align: left;
    width: 100%;
    margin-bottom: 20px;
  }
}

.cards-item__remove {
  display: block;
  text-decoration: underline;
  margin-right: auto;
  background: unset;
  color: #343132;
  cursor: pointer;
  border: 0;
  transition: all .1s ease-in;

  &:hover {
    color: #2F6BF0;
    transform: translateX(-2px);
  }
}

.cards-item__row__number {
  font-weight: 600;
  font-size: 16px;
}
</style>
